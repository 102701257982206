<template>
  <section>
    <el-button type="text" size="mini" @click="userChoose">选择人员</el-button>
    <el-dialog
      :visible.sync="addUserVisible"
      v-model="addUserVisible"
      width="75%"
      center
      @close="diaclose"
      append-to-body
    >
      <div class="searchdiv">
        <el-row>
          <el-col :span="6">
            <el-row>
              <el-col :span="4">
                <span>账号:</span>
              </el-col>
              <el-col :span="19">
                <el-input v-model="usernameinput"></el-input>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="9">
            <el-row>
              <el-col :span="5">
                <span>人员名称:</span>
              </el-col>
              <el-col :span="17">
                <el-input v-model="nameinput"></el-input>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="1.8">
            <el-radio v-model="poststatus" label="1" :disabled="one">有职务</el-radio>
          </el-col>
          <el-col :span="1.8">
            <el-radio v-model="poststatus" label="2" :disabled="one">无职务</el-radio>
          </el-col>
          <el-col :span="2">
            <el-radio v-model="poststatus" label="0" :disabled="one">全部</el-radio>
          </el-col>
          <el-col :span="1.8">
            <el-button type="primary" @click="postsearch">查询</el-button>
          </el-col>
        </el-row>
      </div>
      <!--组织维护树形工具条-->
      <div class="userdiv">
        <el-col :span="6">
          <el-card>
            <div slot="header">
              <span style="font-size:17px">组织结构</span>
            </div>
            <div id="depttree">
              <el-scrollbar style="height:100%">
                <el-tree
                  :load="loadDptNode"
                  :props="RoleProps"
                  lazy
                  @node-click="PostClick"
                  v-if="datatree"
                  highlight-current
                ></el-tree>
              </el-scrollbar>
            </div>
          </el-card>
        </el-col>
        <!--待选人员列表-->
        <el-col :span="9">
          <el-card>
            <div slot="header">
              <span style="font-size:17px">待选人员</span>
            </div>
            <div id="prediv">
              <el-table :data="userform" style="width: 100%;">
                <el-table-column type="index" width></el-table-column>
                <el-table-column label="账号" prop="UserName" width></el-table-column>
                <el-table-column label="姓名" prop="UserNameClaim" width></el-table-column>
                <el-table-column label="部门" prop="DepartmentName" width></el-table-column>
                <el-table-column label="操作">
                  <template scope="scope">
                    <el-button icon="el-icon-right" @click="addNewUser(scope.row)"></el-button>
                  </template>
                </el-table-column>
              </el-table>
              <!-- 分页器 -->
              <el-pagination
                align="center"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-size="pageSize"
                layout="prev, pager, next, jumper"
                :page-count="total"
                :total="totaldata"
                v-show="postshow"
              ></el-pagination>
              <!--分页器2-->
              <el-pagination
                align="center"
                @current-change="handleCurrentChange2"
                :current-page="currentPage2"
                :page-size="pageSize2"
                layout="prev, pager, next, jumper"
                :page-count="total2"
                :total="totaldata2"
                v-show="searchshow"
              ></el-pagination>
            </div>
          </el-card>
        </el-col>
        <!--已选人员列表-->
        <el-col :span="9">
          <el-card>
            <div slot="header">
              <span style="font-size:17px">已选人员</span>
            </div>
            <div id="gotdiv">
              <el-scrollbar style="height:100%">
                <el-table :data="gotuserform" style="width: 100%;">
                  <el-table-column type="index"></el-table-column>
                  <el-table-column label="账号" prop="UserName" width></el-table-column>
                  <el-table-column label="姓名" prop="UserNameClaim" width></el-table-column>
                  <el-table-column label="操作">
                    <template scope="scope">
                      <el-button icon="el-icon-back" @click="deleteNewUser(scope.row)"></el-button>
                    </template>
                  </el-table-column>
                  <!-- <el-table-column label="是否主职务" prop="Principal" width>
                            <template slot-scope = "scope">
                                <el-tag :type = "scope.row.Principal ? 'success' : 'danger'"  disable-transitions>
                                    {{scope.row.Principal ? "是" : "否"}}
                                </el-tag>
                            </template>
                  </el-table-column>-->
                </el-table>
              </el-scrollbar>
            </div>
          </el-card>
        </el-col>
      </div>
      <div slot="footer" style="text-align: center;">
        <el-button type="primary" @click="addNewSubmit">提交</el-button>
      </div>
    </el-dialog>
  </section>
</template>
<script>
import { GetChildrenList, GetDepartmentPostUserList, GetPostUserList, AddNewUser, GetUserChooseList, GetPostUserPrincipalList } from '../api/hr';
export default {
  name: "Userchoosetool",
  data () {
    return {
      //   dialogWidth: 0,
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 5, // 每页的数据条数
      page: 1,
      currentPage2: 1, // 当前页码
      total2: null, // 总条数
      totaldata2: null,
      pageSize2: 5, // 每页的数据条数
      page2: 1,
      node2: null,    //暂存选中node信息
      one: false,
      deptname: null,   //双向绑定部门name
      usernameinput: null,
      nameinput: null,
      poststatus: '1',
      postshow: true,
      searchshow: false,
      addUserVisible: false,
      datatree: false,   //树重新加载
      userform: [],    //初始化待选名单列表
      gotuserform: [],  //初始化已选人员名单
      newcontent: null,  //暂存新增人员内容
      RoleProps: {
        label: 'Name',
        isLeaf: 'Leaf'
      }
    }
  },
  props: ["callbackmsg"], //接受父组件传值
  methods: {
    //组织维护节点加载初始化
    loadDptNode (node, resolve) {
      if (node.level === 0) {
        this.loadfirstnode(resolve);
      }
      if (node.level >= 1 && node.isLeaf == false) {
        this.loadchildnode(node, resolve);
      }
    },
    //组织维护加载第一级节点
    loadfirstnode (resolve) {
      GetChildrenList().then((res) => {
        const roledata = res.data.response;
        resolve(roledata);
      });
    },
    //组织维护加载节点的子节点集合
    loadchildnode (node, resolve) {
      let para = { departmentId: node.data.Id };
      GetChildrenList(para).then((res) => {
        const roledata = res.data.response;
        resolve(roledata);
      });
    },
    getSearchData () {
      this.node2 = null;
      let para = {
        userName: this.usernameinput,
        userCliamName: this.nameinput,
        enumPost: this.poststatus,
        page: this.page2,
        size: this.pageSize2
      };
      GetPostUserPrincipalList(para).then((res) => {
        this.total2 = res.data.response.pageCount;
        this.totaldata2 = res.data.response.dataCount;
        this.userform = res.data.response.data;
      });
    },
    handleCurrentChange (val) {
      this.page = val;
      this.getPreUsers();
      this.page = 1;
    },
    handleCurrentChange2 (val) {
      this.page2 = val;
      this.getSearchData();
      this.page2 = 1;
    },
    //查询主职务人员
    postsearch () {
      this.postshow = false;
      this.searchshow = true;
      this.getSearchData();
    },
    //人员选择弹框显示
    userChoose () {
      console.info(this.callbackmsg);
      let check = this.callbackmsg instanceof Array;
      let para;
      this.addUserVisible = true;
      if (this.callbackmsg == "dutypeo" || this.callbackmsg == "assignpeo" || this.callbackmsg == "outpeople" || this.callbackmsg == "acceptpeople" || this.callbackmsg == "reslovepeo" || this.callbackmsg == "orderpeo") {
        this.one = true;
      } else {
        this.one = false;
      }
      this.userform = [];
      this.datatree = true;
      if (this.callbackmsg.length != 0 && check == true) {
        para = this.callbackmsg ;
      } else {
        para = [];
      }
      GetUserChooseList(para).then((res) => {
        this.gotuserform = res.data.response;
      });
    },
    //职务树节点点击事件
    PostClick (node) {
      this.postshow = true;
      this.searchshow = false;
      this.deptname = node.Name;
      this.node2 = node;
      this.getPreUsers();
    },
    getPreUsers () {
      let para;
      if (this.node2 != null) {
        let node3 = this.node2;
        para = {
          departmentId: node3.Id,
          page: this.page,
          size: this.pageSize
        };
      } else {
        para = {
          page: this.page,
          size: this.pageSize
        };
      }
      GetDepartmentPostUserList(para).then((res) => {
        if (res.data.response.data != null) {
          this.total = res.data.response.pageCount;
          this.totaldata = res.data.response.dataCount;
          this.userform = res.data.response.data;
        } else {
          this.userform = [];
        }
      });
    },
    //从待选人员中选择人员
    addNewUser (row) {
      let para = Object.assign({}, row);
      let check = this.callbackmsg instanceof Array;
      let adduser = {
        Id: row.UserId,
        UserName: row.UserName,
        UserNameClaim: row.UserNameClaim,
      };
      if (this.gotuserform == null) {
        this.gotuserform = new Array();
        this.gotuserform.push(adduser);
      } else if (check == false && this.gotuserform.length == 1) {
        this.$message({
          message: '当前人员只能选一人',
          type: "error"
        });
      } else {
        this.gotuserform.push(adduser);
      }
    },
    //删除以选中职务人员
    deleteNewUser (row) {
      this.gotuserform.splice(
        this.gotuserform.indexOf(row),
        1);
    },
    //提交选中新人员弹框
    addNewSubmit () {
      // let para = this.newcontent;
      var newdata = new Array();
      var dname = this.deptname;
      for (var i = 0; i < this.gotuserform.length; i++) {
        newdata.push(this.gotuserform[i].Id);
      }
      if (this.callbackmsg == "dutypeo") {
        this.$emit("dutycallFunction", newdata); //将值传给责任人
      } else if (this.callbackmsg == "assignpeo") {
        this.$emit("assigncallFunction", newdata); //将值传给指派人
      } else if (this.callbackmsg == "outpeople") {    //将值传给跨出人员
        this.$emit("outcallFunction", newdata, dname);
      } else if (this.callbackmsg == "acceptpeople") {
        this.$emit("acceptcallFunction", newdata, dname);
      } else if (this.callbackmsg == "reslovepeo") {
        this.$emit("reslovecallFunction", newdata);
      } else if (this.callbackmsg == "orderpeo") {
        this.$emit("ordercallFunction", newdata);
      } else {
        this.$emit("usercallFunction", newdata); //将值传给父组件
      }
      this.addUserVisible = false;
      this.datatree = false;
      this.gotuserform = [];
      this.usernameinput = null;
      this.nameinput = null;
      //   this.poststatus = null;
      this.total = null;
      this.totaldata = null;
      this.pageSize = 5;
      this.currentPage = 1;
      this.deptname = null;
      dname = null;
    },
    diaclose () {
      this.datatree = false;
      this.userform = [];
      this.usernameinput = null;
      this.nameinput = null;
      // this.poststatus = null;
      this.total = null;
      this.totaldata = null;
      this.pageSize = 5;
      this.currentPage = 1;
      this.deptname = null;
    }
  },
  mounted () {

  }
}
</script>
<style scoped>
.userdiv {
  height: calc(100vh - 350px);
}
#depttree {
  height: 425px;
  /* overflow: scroll; */
}
#prediv {
  height: 425px;
  /* overflow: scroll; */
}
#gotdiv {
  height: 425px;
  /* overflow: scroll; */
}
.searchdiv {
  margin-bottom: 0.5%;
}
/* .el-scrollbar .el-scrollbar__wrap {overflow-x: hidden;}
.el-tree>.el-tree-node{

height: 350px; 
min-width: 100%;
display:inline-block;
} */
</style>