<template>
  <el-col v-if="buttonList.length>0" :span="24" class="toolbar" style="padding-bottom: 0px;">
    <el-form :inline="true" @submit.native.prevent>
      <el-form-item>
        <el-input :style="'width:'+mywidth+'px'"
          v-model="searchVal"
          :placeholder="searchmsg"
          v-show="showVal"
          size="small"
          clearable
        ></el-input>
        <el-date-picker  v-show="buttonListmsg=='lihui'" value-format="yyyy/MM/dd"
          v-model="date1" type="date" placeholder="选择会议日期">
        </el-date-picker>
      </el-form-item>
      <!-- 这个就是当前页面内，所有的btn列表 -->
      <el-form-item v-for="item in buttonList" :key="item.Id" :value="item.Name">
        <!-- 这里触发点击事件 -->
        <el-button
          type="primary"
          @click="callFunc(item)"
          size="small"
          :icon="item.Icon"
          round
        >{{item.Name}}</el-button>
      </el-form-item>
    </el-form>
  </el-col>
</template>
<script>
import moment from 'moment-timezone'

export default {
  name: "Toolbar",
  data () {
    return {
      searchVal: "", //双向绑定搜索内容
      date1:'',
      showVal: true,
      searchmsg: null,
      mywidth:200
    };
  },
  props: ["buttonList", "buttonListmsg"], //接受父组件传值
  methods: {
    handleDateChange(v)
    {
      console.log(v)
      this.date1=moment(new Date(v)).format('YYYY/MM/DD');
    },
    callFunc (item) {
      item.search = this.searchVal;
      item.date1 = this.date1;
      this.$emit("callFunction", item); //将值传给父组件
    }
  },
  mounted () {
    // console.info(this.buttonListmsg);
    if (this.buttonListmsg == "tasktype" || this.buttonListmsg == "hidde" || this.buttonListmsg == "RegularMeetingmsg") {
      this.showVal = false;
    } else {
      this.showVal = true;
      this.mywidth=200;
      // console.log(this.buttonListmsg)
      if (this.buttonListmsg == "taskmanage") {
        this.searchmsg = "请输入工作项目进行查询";
      } else if (this.buttonListmsg == "RegularMeetingmsg") {
        this.searchmsg = "请输入会议名称查询";
      } else if (this.buttonListmsg == "departmenttype") {
        this.searchmsg = "请输入部门类型名称进行查询"
      } else if (this.buttonListmsg == "departmentrank") {
        this.searchmsg = "请输入部门级别名称进行查询"
      } else if (this.buttonListmsg == "postrank") {
        this.searchmsg = "请输入职务级别名称进行查询"
      } else if (this.buttonListmsg == "menus") {
        this.searchmsg = "请输入菜单名称进行查询"
      } else if (this.buttonListmsg == "interface") {
        this.searchmsg = "请输入接口地址进行查询"
      } else if (this.buttonListmsg == "users") {
        this.searchmsg = "请输入登录名进行查询"
      } else if (this.buttonListmsg == "myimportyearplantask") {
        this.searchmsg = "输入工作项目或责任人"
      } else if(this.buttonListmsg == "lihui"){
        this.searchmsg = "请输入工作项目进行查询";
        this.mywidth=200;
      } else if (this.buttonListmsg == "searchquanxian") {
        this.searchmsg = "输入授权人姓名进行查询"
      }
      else {
        this.searchmsg = this.buttonListmsg;
      }

    }
  }
};
</script>